import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { XSeriesFamilySeriesProps } from "./Xseries.d"
import { Image } from "../../atoms/Image"
import { Link } from "../../atoms/Link"
import { css } from "@emotion/react"
import { LanguageContext } from "../../../contexts/Language"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import Icon from "../../atoms/Icon"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks, toUsd } from "../../../helpers"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import CheckMarkInCircle from "../../atoms/Icon/CheckMarkInCircle"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const XSeriesFamilyDetails: React.FC<XSeriesFamilySeriesProps> = ({
  models,
  selectedModel,
}) => {
  // Group the models as an array where the series.name is the same
  // Temporary solution to filter out Camry and Tacoma as requested on #187966609
  const groupedModels = models
    .filter(
      (model: any) =>
        model.series.name !== "Camry" && model.series.name !== "Tacoma"
    )
    .reduce((r: { [key: string]: any[] }, a) => {
      r[a.series.name] = [...(r[a.series.name] || []), a]
      return r
    }, {})

  const { _ } = useContext(LanguageContext)
  const currentSlug =
    typeof window !== "undefined" && window?.location?.pathname?.split("/")[2]

  const hoverAnimation = css`
    &:hover > .darkRed {
      ${tw`translate-x-11 duration-300`}
    }
    &:hover > .lighterRed {
      ${tw`opacity-100 delay-200`}
    }
    &:hover > .lightestRed {
      ${tw`opacity-100 delay-100`}
    }
  `

  //this function is just a prelimary way to get images by angle and a color -- will need some revisions but works for now
  const getExteriorImageByColorAndAngle = (
    colorCode: string,
    model: any,
    angle: number
  ) => {
    //get the color from the colors object where exterior.code is the same as the color
    const exteriorColor = model.colors.find(
      (c: { exterior: { code: string } }) => c.exterior.code === colorCode
    )

    if (!exteriorColor) {
      //if we dont have a color that matches the color code supplied then take the first one
      return model.colors[0].exteriorImages[angle]
    }
    return exteriorColor?.exteriorImages
      ? exteriorColor?.exteriorImages[angle]
      : model.jellybean
  }
  const [{}, disclaimersDispatch] = useContext(DisclaimersContext)
  const languageContextValue = useContext(LanguageContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()

  const tabAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const focusAnimation = css`
    &:focus-visible:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
    content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:focus-visible:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const active = css`
    ${tw`font-semibold focus-visible:(border-transparent)`}
    &:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
    &:hover:after {
      ${tw`h-1 bg-red-400`}
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
  `

  const redUnderlineAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-red-400 origin-bottom-left transition-transform ease-out duration-300`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left`}
      }
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`origin-bottom-left`}
    }
  `

  const categories = [
    "All XSeries",
    "Cars & Minivans",
    "Crossovers & SUVs",
    "Trucks",
  ]
  const [xSeriesTab, setXSeriesTab] = useState("all xseries")

  const [isTabOpen, setIsTabOpen] = useState(false)

  const [shouldAnimate, setShouldAnimate] = useState(false)

  let counter = 0

  useEffect(() => {
    setShouldAnimate(true)
    const timeoutId = setTimeout(() => {
      setShouldAnimate(false)
    }, 3000) // Change this to the duration of your animation

    return () => clearTimeout(timeoutId)
  }, [])

  const [selectedXSeries, setSelectedXSeries] = useState()

  const handleUpdateXSeries = (newXSeries: any) => {
    LocalStorageClient.write("selectedXSeries", newXSeries)
    setSelectedXSeries(newXSeries)
  }

  useEffect(() => {
    const storedXSeries = LocalStorageClient.read("selectedXSeries")
    if (storedXSeries) {
      setXSeriesTab(String(storedXSeries))
    }
  }, [])
  const carsAndMinivans = Object.entries(groupedModels)
    .filter(([key, value]) => key.toLowerCase() === "camry")
    .flatMap(([key, value]) => value)

  const crossOversAndSUVs = Object.entries(groupedModels)
    .filter(
      ([key, value]) =>
        key.toLowerCase() === "highlander" ||
        key.toLowerCase() === "rav4" ||
        key.toLowerCase() === "4runner"
    )
    .flatMap(([key, value]) => value)
  const trucks = Object.entries(groupedModels)
    .filter(
      ([key, value]) =>
        key.toLowerCase() === "tacoma" || key.toLowerCase() === "tundra"
    )
    .flatMap(([key, value]) => value)
  return (
    <>
      <div
        css={[
          tw`bg-gunmetal flex relative min-h-[64px] w-full`,
          tw`md:(justify-center w-full items-center)`,
        ]}
      >
        <button
          type="button"
          css={[
            tw`absolute right-8 top-4 z-20 flex justify-center items-center h-[24px]`,
            tw`md:(px-0)`,
            tw`focus-visible:(outline-gray)`,
            "-webkit-tap-highlight-color: transparent;",
          ]}
          aria-expanded={isTabOpen}
          onClick={() => {
            setIsTabOpen(!isTabOpen)
            setShouldAnimate(true)

            const timeoutId = setTimeout(() => {
              setShouldAnimate(false)
            }, 300)

            return () => clearTimeout(timeoutId)
          }}
        >
          <span
            css={[
              tw`text-white transition-all duration-300 xl:(inline-flex opacity-100 pr-3 transform)`,
            ]}
          >
            <Icon.Chevron
              color="red-400"
              direction="down"
              css={[
                tw`h-2.5 transition-all duration-300 inline-flex mt-1`,
                isTabOpen ? tw`transform -rotate-180` : tw`transform rotate-0`,
                shouldAnimate && tw`transform -rotate-180`,
              ]}
            />
          </span>
        </button>
        <div
          css={[
            tw`flex flex-nowrap justify-between overflow-x-auto w-4/5 py-3 scrollbar-hide`,
            tw`md:(flex justify-center pb-3 pt-1.5 w-full items-center)`,
          ]}
        >
          {categories?.map((cat, index) => {
            const disabled =
              (cat === "Cars & Minivans" && carsAndMinivans.length == 0) ||
              (cat === "Crossovers & Suvs" && crossOversAndSUVs.length == 0) ||
              (cat === "Trucks" && trucks.length == 0)
            return (
              <div css={tw`md:relative`}>
                <button
                  onClick={() => {
                    const newTab = cat.toLowerCase()
                    setXSeriesTab(newTab)
                    handleUpdateXSeries(newTab)
                    setIsTabOpen(true)
                    trackTealEvent({
                      tealium_event: "refinement",
                      page_type: "x-series",
                      refinement_value: `x-series|x-series|Vehicle Segment|${cat}`,
                    })
                  }}
                  role="tab"
                  css={[
                    tabAnimation,
                    focusAnimation,
                    tw`cursor-pointer text-white whitespace-nowrap inline-block relative !py-2 mx-6 capitalize`,
                    xSeriesTab === cat.toLocaleLowerCase() && active,
                    disabled && tw`text-gray-400 pointer-events-none`,
                  ]}
                  analytics-id={`segment selector:refinement:${index + 1}`}
                >
                  {_(cat)}
                </button>
              </div>
            )
          })}
        </div>
      </div>
      {isTabOpen && xSeriesTab === "cars & minivans" && categories && (
        <>
          <div
            css={[
              tw`col-span-12 relative bg-gradient-to-b from-gray-100 sm:(mb-6) lg:(mb-0)`,
            ]}
          >
            <ul
              css={[
                tw`flex justify-center items-start flex-wrap pb-4 pt-4 scrollbar-hide`,
                tw`lg:(min-h-[253px])`,
              ]}
            >
              {carsAndMinivans.map((model: any, index) => (
                <li
                  css={[
                    tw`list-none flex flex-col justify-center w-full items-center py-4 sm:(w-52)`,
                  ]}
                >
                  <div css={[tw`flex sm:(block)`]}>
                    <div css={[tw`w-1/2 ml-8 sm:(w-full ml-0)`]}>
                      <Link
                        to={`/xseries/${model.series.name.toLowerCase()}`}
                        state={{ modelName: model.name }}
                        onClick={() => {
                          trackTealEvent({
                            vehicle_model: `${model.series.name} ${model.name}`,
                            tealium_event: "nav_click",
                            click_text: `series selector:${model.series.name} ${model.name}`,
                          })
                        }}
                        analytics-id={`series link:model selector:${index + 1}`}
                        css={[tw`w-full relative p-0`, hoverAnimation]}
                      >
                        <Image
                          imageData={getExteriorImageByColorAndAngle(
                            "0040",
                            model,
                            1
                          )}
                          objectFit="cover"
                        />
                      </Link>
                    </div>
                    <div css={[tw`w-1/2 mx-8 sm:(w-full mx-0)`]}>
                      <Link
                        to={`/xseries/${model.series.name.toLowerCase()}`}
                        state={{ modelName: model.name }}
                        onClick={() => {
                          trackTealEvent({
                            vehicle_model: `${model.series.name} ${model.name}`,
                            tealium_event: "nav_click",
                            click_text: `series selector:${model.series.name} ${model.name}`,
                          })
                        }}
                        analytics-id={`series link:model selector:${index + 1}`}
                        css={[tw`w-full relative p-0`, hoverAnimation]}
                      >
                        <h4 css={[tw`font-semibold text-center`]}>
                          {model.series.name} {model.name}
                        </h4>
                      </Link>
                      {model?.costs?.baseMSRP && (
                        <div css={[tw`block text-center`]}>
                          <div css={[tw`flex-col`]}>
                            <span>$</span>
                            {toUsd(model?.costs?.baseMSRP)}
                            <span css={tw`relative`}>
                              {parseDisclaimerBlocks(`[starting_msrp]`, code =>
                                disclaimersDispatch(
                                  toggleDisclaimersModal(code)
                                )
                              )}
                            </span>
                          </div>
                          <div css={[tw`font-semibold`, "font-size: 10px"]}>
                            {languageContextValue._("Starting MSRP")}
                          </div>
                        </div>
                      )}
                      {`${model.series.name.toLowerCase()}` === currentSlug &&
                        selectedModel === `${model.name}` && (
                          <CheckMarkInCircle
                            color="green"
                            css={tw`w-6 mx-auto mt-2`}
                          />
                        )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            )
          </div>
        </>
      )}
      {isTabOpen && xSeriesTab === "all xseries" && categories && (
        <>
          <div
            css={[
              tw`col-span-12 relative bg-gradient-to-b from-gray-100 sm:(mb-6) lg:(mb-0)`,
            ]}
          >
            <ul
              css={[
                tw`flex justify-center min-h-[253px] items-start flex-wrap pb-4 pt-4 scrollbar-hide`,
              ]}
            >
              {Object.entries(groupedModels).map(([key, value]) =>
                value.map((model: any) => {
                  counter++

                  return (
                    <li
                      css={[
                        tw`list-none flex flex-col justify-center w-full items-center py-4 sm:(w-52)`,
                      ]}
                    >
                      <div css={[tw`flex sm:(block)`]}>
                        <div css={[tw`w-1/2 ml-8 sm:(w-full ml-0)`]}>
                          <Link
                            to={`/xseries/${model.series.name.toLowerCase()}`}
                            state={{ modelName: model.name }}
                            onClick={() => {
                              trackTealEvent({
                                vehicle_model: `${model.series.name} ${model.name}`,
                                tealium_event: "nav_click",
                                click_text: `series selector:${model.series.name} ${model.name}`,
                              })
                            }}
                            analytics-id={`series link:model selector:${counter}`}
                            css={[tw`w-full relative p-0`, hoverAnimation]}
                          >
                            <Image
                              imageData={getExteriorImageByColorAndAngle(
                                "0040",
                                model,
                                model.name === "XP Predator" ? 0 : 1
                              )}
                              objectFit="cover"
                              css={[
                                tw`w-full`,
                                model.series.name === "4Runner" && tw`px-2`,
                              ]}
                            />
                          </Link>
                        </div>
                        <div css={[tw`w-1/2 mx-8 sm:(w-full mx-0)`]}>
                          <Link
                            to={`/xseries/${model.series.name.toLowerCase()}`}
                            state={{ modelName: model.name }}
                            onClick={() => {
                              trackTealEvent({
                                vehicle_model: `${model.series.name} ${model.name}`,
                                tealium_event: "nav_click",
                                click_text: `series selector:${model.series.name} ${model.name}`,
                              })
                            }}
                            analytics-id={`series link:model selector:${counter}`}
                            css={[tw`w-full relative p-0`, hoverAnimation]}
                          >
                            <h4 css={[tw`font-semibold text-center`]}>
                              {model.series.name} {model.name}
                            </h4>
                          </Link>
                          {model?.costs?.baseMSRP && (
                            <div css={[tw`block text-center`]}>
                              <div css={[tw`flex-col`]}>
                                <span>$</span>
                                {toUsd(model?.costs?.baseMSRP)}
                                <span css={tw`relative`}>
                                  {parseDisclaimerBlocks(
                                    `[starting_msrp]`,
                                    code =>
                                      disclaimersDispatch(
                                        toggleDisclaimersModal(code)
                                      )
                                  )}
                                </span>
                              </div>
                              <div css={[tw`font-semibold`, "font-size: 10px"]}>
                                {languageContextValue._("Starting MSRP")}
                              </div>
                            </div>
                          )}
                          {`${model.series.name.toLowerCase()}` ===
                            currentSlug &&
                            selectedModel === `${model.name}` && (
                              <CheckMarkInCircle
                                color="green"
                                css={tw`w-6 mx-auto mt-2`}
                              />
                            )}
                        </div>
                      </div>
                    </li>
                  )
                })
              )}
            </ul>
          </div>
        </>
      )}
      {isTabOpen && xSeriesTab === "crossovers & suvs" && categories && (
        <>
          <div
            css={[
              tw`col-span-12 relative bg-gradient-to-b from-gray-100 sm:(mb-6) lg:(mb-0)`,
            ]}
          >
            <ul
              css={[
                tw`flex justify-center items-start min-h-[253px] flex-wrap pb-4 pt-4 scrollbar-hide`,
              ]}
            >
              {crossOversAndSUVs.map((model: any, index) => (
                <li
                  css={[
                    tw`list-none flex flex-col justify-center w-full items-center py-4 sm:(w-52)`,
                  ]}
                >
                  <div css={[tw`flex sm:(block)`]}>
                    <div css={[tw`w-1/2 ml-8 sm:(w-full ml-0)`]}>
                      <Link
                        to={`/xseries/${model.series.name.toLowerCase()}`}
                        state={{ modelName: model.name }}
                        onClick={() => {
                          trackTealEvent({
                            vehicle_model: `${model.series.name} ${model.name}`,
                            tealium_event: "nav_click",
                            click_text: `series selector:${model.series.name} ${model.name}`,
                          })
                        }}
                        analytics-id={`series link:model selector:${index + 1}`}
                        css={[tw`w-full relative p-0`, hoverAnimation]}
                      >
                        <Image
                          imageData={getExteriorImageByColorAndAngle(
                            "0040",
                            model,
                            model.name === "XP Predator" ? 0 : 1
                          )}
                          objectFit="cover"
                        />
                      </Link>
                    </div>
                    <div css={[tw`w-1/2 mx-8 sm:(w-full mx-0)`]}>
                      <Link
                        to={`/xseries/${model.series.name.toLowerCase()}`}
                        state={{ modelName: model.name }}
                        onClick={() => {
                          trackTealEvent({
                            vehicle_model: `${model.series.name} ${model.name}`,
                            tealium_event: "nav_click",
                            click_text: `series selector:${model.series.name} ${model.name}`,
                          })
                        }}
                        analytics-id={`series link:model selector:${index + 1}`}
                        css={[tw`w-full relative p-0`, hoverAnimation]}
                      >
                        <h4 css={[tw`font-semibold text-center`]}>
                          {model.series.name} {model.name}
                        </h4>
                      </Link>
                      {model?.costs?.baseMSRP && (
                        <div css={[tw`block text-center`]}>
                          <div css={[tw`flex-col`]}>
                            <span>$</span>
                            {toUsd(model?.costs?.baseMSRP)}
                            <span css={tw`relative`}>
                              {parseDisclaimerBlocks(`[starting_msrp]`, code =>
                                disclaimersDispatch(
                                  toggleDisclaimersModal(code)
                                )
                              )}
                            </span>
                          </div>
                          <div css={[tw`font-semibold`, "font-size: 10px"]}>
                            {languageContextValue._("Starting MSRP")}
                          </div>
                        </div>
                      )}
                      {`${model.series.name.toLowerCase()}` === currentSlug &&
                        selectedModel === `${model.name}` && (
                          <CheckMarkInCircle
                            color="green"
                            css={tw`w-6 mx-auto mt-2`}
                          />
                        )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
      {isTabOpen && xSeriesTab === "trucks" && categories && (
        <>
          <div
            css={[
              tw`col-span-12 relative bg-gradient-to-b from-gray-100 sm:(mb-6) lg:(mb-0)`,
            ]}
          >
            <ul
              css={[
                tw`flex justify-center items-start flex-wrap pb-4 pt-4 min-h-[253px] scrollbar-hide`,
              ]}
            >
              {trucks.map((model: any, index, cat) => (
                <li
                  css={[
                    tw`list-none flex flex-col justify-center w-full items-center py-4 sm:(w-52)`,
                  ]}
                >
                  <div css={[tw`flex sm:(block)`]}>
                    <div css={[tw`w-1/2 ml-8 sm:(w-full ml-0)`]}>
                      <Link
                        to={`/xseries/${model.series.name.toLowerCase()}`}
                        state={{ modelName: model.name }}
                        onClick={() => {
                          trackTealEvent({
                            vehicle_model: `${model.series.name} ${model.name}`,
                            tealium_event: "nav_click",
                            click_text: `series selector:${model.series.name} ${model.name}`,
                          })
                        }}
                        analytics-id={`series link:model selector:${index + 1}`}
                        css={[tw`w-full relative p-0`, hoverAnimation]}
                      >
                        <Image
                          imageData={getExteriorImageByColorAndAngle(
                            "0040",
                            model,
                            1
                          )}
                          objectFit="cover"
                        />
                      </Link>
                    </div>
                    <div css={[tw`w-1/2 mx-8 sm:(w-full mx-0)`]}>
                      <Link
                        to={`/xseries/${model.series.name.toLowerCase()}`}
                        state={{ modelName: model.name }}
                        onClick={() => {
                          trackTealEvent({
                            vehicle_model: `${model.series.name} ${model.name}`,
                            tealium_event: "nav_click",
                            click_text: `series selector:${model.series.name} ${model.name}`,
                          })
                        }}
                        analytics-id={`series link:model selector:${index + 1}`}
                        css={[tw`w-full relative p-0`, hoverAnimation]}
                      >
                        <h4 css={[tw`font-semibold text-center`]}>
                          {model.series.name} {model.name}
                        </h4>
                      </Link>
                      {model?.costs?.baseMSRP && (
                        <div css={[tw`block text-center`]}>
                          <div css={[tw`flex-col`]}>
                            <span>$</span>
                            {toUsd(model?.costs?.baseMSRP)}
                            <span css={tw`relative`}>
                              {parseDisclaimerBlocks(`[starting_msrp]`, code =>
                                disclaimersDispatch(
                                  toggleDisclaimersModal(code)
                                )
                              )}
                            </span>
                          </div>
                          <div css={[tw`font-semibold`, "font-size: 10px"]}>
                            {languageContextValue._("Starting MSRP")}
                          </div>
                        </div>
                      )}
                      {`${model.series.name.toLowerCase()}` === currentSlug &&
                        selectedModel === `${model.name}` && (
                          <CheckMarkInCircle
                            color="green"
                            css={tw`w-6 mx-auto mt-2`}
                          />
                        )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </>
  )
}

export default XSeriesFamilyDetails
